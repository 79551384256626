import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import TechTag from "../components/TechTag"

import "../components/List.css"

import DesignCirclePartImage from "../images/mask@3x.png"
import AppDevThumbnail from "../components/thumbnails/AppDevThumbnail"
import SoftDevThumbnail from "../components/thumbnails/SoftDevThumbnail"
import UiUxThumbnail from "../components/thumbnails/UiUxThumbnail"
import DesignCornerImage from "../images/rectangle-md.svg"

import KcsServiceIcon from "../images/kcs-service-icons/service.png"
import KcsCalloutIcon from "../images/kcs-service-icons/callout.png"
import KcsInstallationIcon from "../images/kcs-service-icons/installation.png"
import KcsDeliveryIcon from "../images/kcs-service-icons/delivery.png"
import KcsMeetingIcon from "../images/kcs-service-icons/meeting.png"
import KcsRegularServiceIcon from "../images/kcs-service-icons/regular-service.png"

const CaseStudyTemplate = ({
  title,
  mainDescription,
  listImage,
  showcaseImage,
  darbiumTitle,
  darbiumDescription,
  services,
  servicesDescription,
  resultsImage,
  resultsTitle,
  results1,
  results2,
  results3,
  results4,
  helmet,
}) => {
  const serviceIcons = [
    KcsServiceIcon,
    KcsMeetingIcon,
    KcsCalloutIcon,
    KcsRegularServiceIcon,
    KcsInstallationIcon,
    KcsDeliveryIcon,
  ]

  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK*/}
      <div className="relative lg:flex container mx-auto py-24 pb-0 px-10 lg:px-32">
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-24 transform rotate-180 hidden md:block"
          style={{ left: "55%", top: "5px" }}
        />
        <div className="lg:w-2/3">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-48">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10 lg:pr-12">
            {mainDescription}
          </p>
          <p className="mt-10 pb-8 md:pb-24">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React Native</TechTag>
          </p>
        </div>
        <div className="lg:w-1/3 mt-10 lg:mt-0 sm:w-3/4">
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="mx-auto w-7/8"
          />
        </div>
      </div>

      {/* SHOWCASE VISUALISATION */}

      <div
        className="relative overflow-hidden"
        style={{
          backgroundImage:
            "linear-gradient(to right, #E5E5E5, rgba(241, 242, 242, 0))",
        }}
      >
        <div className="container mx-auto w-2/3 mt-20">
          <h3 className="font-semibold text-3xl">{darbiumTitle}</h3>
          <p className="mt-5 mb-20 text-xl">{darbiumDescription}</p>
        </div>
        <img
          src={showcaseImage.childImageSharp.fixed.src}
          alt=""
          className="mx-auto w-7/8"
        />
      </div>

      {/* SERVICES */}

      <div className="relative overflow-hidden items-center">
        <div className="relative container mx-auto py-12 pt-16 px-16 md:px-10 lg:px-10">
          <div className="md:flex md:flex-row pb-2">
            <div className="md:w-1/2 text-gray-500">
              <div className="grid grid-cols-3">
                {services.map((service, index) => {
                  return (
                    <div className="mx-auto pt-5 pb-5">
                      <img
                        src={serviceIcons[index]}
                        alt=""
                        className="mx-auto h-10 mb-4"
                      />
                      <p className="font-semibold text-center">{service}</p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="md:w-1/2 ml-20 md:pr-16 z-30">
              <div className="relative">
                <img
                  src={DesignCornerImage}
                  alt=""
                  className="absolute w-10 transform rotate-12 -top-1 md:top-12 -left-8"
                />
                <p className="pt-4 md:pt-20 mb-6">{servicesDescription}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* RESULTS */}

      <div
        className="relative items-center"
        style={{ backgroundColor: "#F8F8F8" }}
      >
        <div className="md:flex md:flex-row">
          <div className="md:w-1/2 bg-[#f4f4f4] self-end">
            <img src={resultsImage.childImageSharp.fixed.src} alt="" />
          </div>
          <div className="md:w-1/2 ml-20 z-30 pr-16">
            <div className="flex col-start-6 col-span-5 order-2 md:items-center mt-10">
              <span className="flex flex-shrink-0 w-4 h-4 bg-green-200 mt-2 mr-2 md:mt-0 md:mr-6 lg:mr-10 lg:w-6 lg:h-6"></span>
              <h2 className="relative font-semibold text-xl lg:text-3xl">
                {resultsTitle}
              </h2>
            </div>
            <div className="relative md:pr-24 pb-12 md:pb-0">
              <p className="pt-5">{results1}</p>
              <p className="pt-5">{results2}</p>
              <p className="pt-5">{results3}</p>
              <p className="pt-5 mb-6">{results4}</p>
              <img
                src={DesignCirclePartImage}
                alt=""
                className="absolute w-24 transform hidden md:block"
                style={{ right: "10%", bottom: "-100px" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <div className="container mx-auto py-32 px-10 lg:px-32">
        <h3 className="text-center font-semibold text-2xl mb-12">
          <FormattedMessage id="case_study.tech_used" values={{ app: title }} />
        </h3>
        <div className="md:flex md:flex-row justify-center">
          <AppDevThumbnail className="md:w-1/3" />
          <SoftDevThumbnail className="md:w-1/3" />
          <UiUxThumbnail className="md:w-1/3" />
        </div>
      </div>
    </div>
  )
}

const KCS = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{project.frontmatter.title}</title>
            <meta
              name="description"
              content={project.frontmatter.mainDescription}
            />
          </Helmet>
        }
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default KCS

export const pageQuery = graphql`
  query KCSID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        mainDescription
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        showcaseImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        darbiumTitle
        darbiumDescription
        services
        servicesDescription
        resultsImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        resultsTitle
        results1
        results2
        results3
        results4
      }
    }
  }
`
